import { Spin } from 'antd';
import { ReactNode } from 'react';
import { TwContainer } from 'src/components/core/TwContainer';

const Loading = ({ children }: { children?: ReactNode }) => {
	return (
		<TwContainer
			className={'tw-h-[100vh] pageLoader tw-flex'}
			full>
			<div className={'tw-m-auto tw-flex tw-flex-col'}>
				<Spin
					size={'large'}
					className={'tw-m-auto tw-font-semibold tw-text-xl'}
					tip={'Loading...'}>
					{children}
				</Spin>
			</div>
		</TwContainer>
	);
};

export default Loading;
