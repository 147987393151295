import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { Footer } from 'src/layouts/Footer';
import { Header } from 'src/layouts/Header';

function MainLayout() {
	return (
		<Layout>
			<Header />
			<Layout.Content>
				<Outlet />
			</Layout.Content>
			<Footer />
		</Layout>
	);
}

export default MainLayout;
