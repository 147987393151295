import { Layout, Menu, MenuProps } from 'antd';
import { TwContainer } from 'src/components/core/TwContainer';
import { Link } from 'react-router-dom';
import { MdReorder } from 'react-icons/all';
import { useState } from 'react';

const items: MenuProps['items'] = [
	{
		key: 'home',
		label: <Link to={'/'}>Dashboard</Link>,
	},
	{
		key: 'about',
		label: <Link to={'/about'}>About Us</Link>,
	},
	{
		key: 'contact',
		label: <Link to={'/contact'}>Contact Us</Link>,
	},
];
export const Header = () => {
	const [current, setCurrent] = useState('home');
	return (
		<Layout.Header className="tw-bg-dark tw-w-full tw-z-50 tw-h-fit tw-top-0 tw-px-4">
			<TwContainer className="tw-flex tw-justify-between tw-text-white tw-py-2">
				<Link
					to={'/'}
					className={'tw-left-12 tw-relative'}>
					<img
						width={150}
						src={'/champtek_horizontal.svg'}
						alt=""
					/>
				</Link>
				<Menu
					onClick={(k) => setCurrent(k.key)}
					className={'tw-bg-transparent tw-border-none tw-text-info tw-h-12 md:tw-w-[500px] tw-w-[56px]'}
					selectedKeys={[current]}
					overflowedIndicator={<MdReorder size={40} />}
					mode="horizontal"
					items={items}
				/>
			</TwContainer>
		</Layout.Header>
	);
};
