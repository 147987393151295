import { TwContainer } from 'src/components/core/TwContainer';
import { Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';

type Props = {};
export const Footer = ({}: Props) => {
	return (
		<Layout.Footer className="tw-bg-[#001B36] tw-py-4">
			<TwContainer className="tw-flex md:tw-flex-row tw-flex-col md:tw-px-10 tw-px-0 tw-gap-8 tw-justify-between md:tw-gap-12 tw-text-white tw-py-16">
				<img
					width={150}
					style={{ transform: 'scale(2)' }}
					src={'/champtek_horizontal.svg'}
					alt=""
				/>
				<div>
					<Typography.Title className={'tw-text-white tw-font-saira'}>Office</Typography.Title>
					<Typography.Paragraph className={'tw-text-white tw-font-saira tw-mb-0'}>
						12351 NW 18 ST,
						<br /> PEMBROKE PINES, FL 33026
					</Typography.Paragraph>
					<Link
						className={'tw-text-info'}
						to={'mailto:info@champteks.com'}>
						info@champteks.com
					</Link>
				</div>
				<div className={'tw-grid tw-gap-4'}>
					<Link to={'/'}>Home</Link>
					<Link to={'/about'}>About Us</Link>
					<Link to={'/contact'}>Contact Us</Link>
				</div>
			</TwContainer>
			<div className={'tw-text-white tw-text-center tw-font-saira'}>Copyright © {new Date().getFullYear()} champtek. All rights reserved.</div>
		</Layout.Footer>
	);
};
