// @flow

import { ReactNode } from 'react';

type Props = {
	children: ReactNode;
	full?: boolean;
	className?: string;
};
export const TwContainer = (props: Props) => {
	return (
		<div className={'container ' + (props.full ? 'tw-w-full !tw-m-0 ' : 'tw-mx-auto tw-max-w-[1400px] ') + props.className || ''}>
			{props.children}
		</div>
	);
};
