import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import MainLayout from './layouts/MainLayout';
import 'src/css/app.scss';
import { App, ConfigProvider } from 'antd';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import ErrorOccurred from 'src/pages/ErrorOccurred';
import Loading from 'src/pages/Loading';

const Home = lazy(() => import('src/pages/Home'));
const AboutUs = lazy(() => import('src/pages/AboutUs'));
const ContactUs = lazy(() => import('src/pages/ContactUs'));
const NotFound = lazy(() => import('src/pages/NotFound'));

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			errorElement={<ErrorOccurred />}
			element={<MainLayout />}>
			<Route
				element={<Home />}
				path={''}
			/>
			<Route
				element={<AboutUs />}
				path={'/about'}
			/>
			<Route
				element={<ContactUs />}
				path={'/contact'}
			/>
			<Route
				element={<NotFound />}
				path={'*'}
			/>
		</Route>,
	),
);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<React.StrictMode>
		<ConfigProvider
			componentSize={'large'}
			theme={{
				token: {
					fontFamily: 'Inter',
					colorPrimary: '#03325A',
					colorError: '#e53935',
					colorInfo: '#17a2b8',
					colorSuccess: '#388e3c',
					colorWarning: '#ffc107',
					screenXS: 0,
					screenXSMin: 0,
					screenXSMax: 767,
					screenSM: 768,
					screenSMMin: 768,
					screenSMMax: 991,
					screenMD: 992,
					screenMDMin: 992,
					screenMDMax: 1199,
					screenLG: 1200,
					screenLGMin: 1200,
					screenLGMax: 1919,
					screenXL: 1920,
					screenXLMin: 1920,
					screenXLMax: 2880,
					screenXXL: 2880,
					screenXXLMin: 2880,
				},
			}}>
			<App>
				<Suspense fallback={<Loading />}>
					<RouterProvider router={router} />
				</Suspense>
			</App>
		</ConfigProvider>
	</React.StrictMode>,
);
