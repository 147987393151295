import { TwContainer } from 'src/components/core/TwContainer';
import { WarningOutlined } from '@ant-design/icons';
import { useRouteError } from 'react-router-dom';

const NotFound = () => {
	const error = useRouteError();
	console.log(error);
	return (
		<TwContainer
			className={'tw-py-96  tw-mx-0 tw-text-center'}
			full>
			<WarningOutlined className={'tw-text-red-500 tw-text-8xl'} />
			<div className={'tw-pt-6 tw-text-4xl tw-font-semibold domaine'}>An error Occurred!</div>
		</TwContainer>
	);
};

export default NotFound;
